export const $me = {
    username: '',
    first_name: '',
    last_name: '',
    password: '',
    email: '',
    role: localStorage.getItem('role_id'),
    role_name: '',
    last_login: '',
    two_factor_auth: false,
    telegram: {
        telegram_chat_id: '',
        notify_type: '',
        notify_type_quantity: 0,
        notify_type_period_offset: 0,
        notify_type_period_last_notification: '1970-01-01 00:00:00',
        notify_type_quantity_countries_last_value: '',
        notify_type_quantity_integrations_last_value: '',
        telegram_last_message_query: '',
    }
}