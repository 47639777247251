<template lang="pug">
		div
				el-row()
						el-col(:xs="24" :sm="12" :md="8" )
								el-image(:src="qr")
						el-col.text-left(:xs="24" :sm="12" :md="16" )
								el-row(:gutter="24")
										el-col(:span="24")
												h5 Instructions
												p First, download Google Authenticator application to your phone.&nbsp;
												p CLick on "+" button and scan the QR Code left to this instruction. An entry "2FA Node App" will show. On your next login you will be promped to add this code.
										el-col(:span="24")
												a(href="https://apps.apple.com/us/app/google-authenticator/id388497605") Download link for iPhone,&nbsp;
												br
												a(href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US") Download link for Android,&nbsp;
										el-col(:xs="24" :sm="12")
												el-form
														el-form-item(label="Validate 2FA"  :error="errors.otp.s ? errors.otp.m : ''" v-bind:class="{'is-invalid': errors.otp.s}")
																el-input( v-model="form.otp" :maxlength="6" placeholder="6-digit code in authenticator app" size="mini")
																		el-button(slot="append" :disabled="form.otp.length < 6" icon="el-icon-check" v-b-tooltip.html.hover.top="", title="Click to validate" @click="validateKey")

</template>
<style lang="scss"></style>
<script>
const $errors = {otp: {s: false, m: ''}};
export default {
		name: 'authenticator',
		data() {
				return {
						qr: '',
						form: {
								otp: ''
						},
						errors: JSON.parse(JSON.stringify($errors))
				}
		},
		mounted() {
				this.$apix.sendHttpRequest('POST', 'me/init/2fa')
						.then(res => {
								this.qr = res;
						})
						.then(err => console.error('[AUTHENTICATOR] error', err))
		},
		methods: {
				validateKey() {
						this.$apix.sendHttpRequest('POST', 'me/validate/2fa', {otp: this.form.otp})
								.then(res => {
										if (res.match) {
												this.$notify.success({title: '2FA Response', message: 'OTP Successfully added'});
												this.onFinish();
										} else {
												this.errors = JSON.parse(JSON.stringify($errors));
												this.$notify.error({title: '2FA Response', message: 'Wrong OTP Code'});
												this.errors.otp = {s: true, m: 'Wrong OTP code typed, please check in the app.'}
										}
								})
								.then(err => console.error('[AUTHENTICATOR] error', err))
				},

				onFinish() {
						this.$emit('onFinish', 'showAuthenticatorModal')
				}
		}
}
</script>