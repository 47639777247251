export const libretranslateLanguages = [
    'Arabic',
    'Azerbaijani',
    'Chinese',
    'Chinese (Traditional)',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Esperanto',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Kabyle',
    'Korean',
    'Occitan',
    'Persian',
    'Polish',
    'Portuguese',
    'Russian',
    'Slovak',
    'Spanish',
    'Swedish',
    'Turkish',
    'Ukranian',
    'Vietnamese',
]

export const daysOfWeek = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

export const workingDaysOfWeek = daysOfWeek.map(e => ({label: e, flag: false, start: '08:00', end: '20:00'}));

export const timePickerOptions = {
    regular: {
        start: '08:30',
        end: '18:30',
        step: '00:30',
    },
    full: {
        start: '00:00',
        end: '23:59',
        step: '00:15',
    },

}

export const dateFormats = [
    'YYYYMMDD',                       // ISO Basic Date
    'YYYYMMDD HH:mm:ss',                       // ISO Basic Date
    'YYYYDDMM',                       // ISO Basic Date (US)
    'YYYYDDMM HH:mm:ss',                       // ISO Basic Date (US)
    'YYYY-MM-DD',                     // ISO Extended Date
    'YYYY-MM-DD HH:mm:ss',                     // ISO Extended Date
    'YYYY-DD-MM',                     // ISO Extended Date (US)
    'YYYY-DD-MM HH:mm:ss',                     // ISO Extended Date (US)
    'YYYYMMDDTHHmmss',                // ISO Basic DateTime
    'YYYYDDMMTHHmmss',                // ISO Basic DateTime (US)
    'YYYY-MM-DDTHH:mm:ss',            // ISO Extended DateTime
    'YYYY-MM-DDTHH:mm:ssZ',           // ISO Extended DateTime with Timezone
    'YYYY-DD-MMTHH:mm:ss',            // ISO Extended DateTime (US)
    'YYYY-DD-MMTHH:mm:ssZ',           // ISO Extended DateTime with Timezone (US)
    'ddd, DD MMM YYYY HH:mm:ss ZZ',   // RFC 2822 Date
    'x',                              // Unix Millisecond Timestamp
    'L',                              // Localized Date Format (short date)
    'LL',                             // Localized Date Format (month day, year)
    'LLL',                            // Localized Date Format (month day, year, time)
    'LLLL',                           // Localized Date Format (day of week, month day, year, time)
    'YYYY-DDD',                       // Day of the Year
    'YYYY-[W]WW',                     // Week of the Year (basic)
    'GGGG-[W]WW-E',                   // ISO Week Year with Week and Weekday
    'MM-DD-YYYY',                     // Custom Format Example (US)
    'DD-MM-YYYY',                     // Custom Format Example
    'YYYY/MM/DD',                     // Custom Format Example
    'YYYY/DD/MM',                     // Custom Format Example (US)
    'MM.DD.YYYY',                     // Custom Format Example (US)
    'DD.MM.YYYY',                     // Custom Format Example
    'MM/DD/YYYY',                     // Custom Format Example (US)
    'DD/MM/YYYY',                     // Custom Format Example

    'MM-DD-YYYY HH:mm:ss',                     // Custom Format Example - full (US)
    'DD-MM-YYYY HH:mm:ss',                     // Custom Format Example - full
    'YYYY/MM/DD HH:mm:ss',                     // Custom Format Example - full
    'YYYY/DD/MM HH:mm:ss',                     // Custom Format Example - full (US)
    'MM.DD.YYYY HH:mm:ss',                     // Custom Format Example - full (US)
    'DD.MM.YYYY HH:mm:ss',                     // Custom Format Example - full
    'MM/DD/YYYY HH:mm:ss',                     // Custom Format Example - full (US)
    'DD/MM/YYYY HH:mm:ss',                     // Custom Format Example - full
];

const langHash = {};
libretranslateLanguages.forEach((e) => langHash[e] = e);

export const libretranslateLanguagesHash = langHash;