<template lang="pug">
	div.text-left
		el-row(:gutter="12")
			p Use this form to reset your password. The new password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one number and one special character.
		el-form(:form="form" :model="form" :rules="rules" ref="form" label-width="120px" label-position="top" )
			el-row(:gutter="24")
				el-col(:xs="24" :sm="12" :md="8")
					el-form-item(label="Old Password" prop="old")
						el-input(v-model="form.old" type="password" placeholder="Old Password" show-password="")
				el-col(:xs="24" :sm="12" :md="8")
					el-form-item(label="New Password" prop="password")
						el-input(v-model="form.password" type="password" placeholder="New Password" :disabled="form.old === ''" show-password="")
				el-col(:xs="24" :sm="12" :md="8")
					el-form-item(label="Confirm Password" prop="confirm")
						el-input(v-model="form.confirm" type="password" placeholder="Confirm Password" :disabled="form.old === ''" show-password="")
			.d-flex.justify-content-center.mt-3
				el-button(type='success', @click='submit' :disabled="formInvalid") Submit
				el-button(type='danger', @click='closeModal') Close


</template>

<style lang="scss">

</style>

<script>
export default {
	name: 'reset-password',
	data() {
		return {
			form: {
				old: '',
				password: '',
				confirm: ''
			},
			rules: {
				old: [
					{ required: true, message: 'Old password is required', trigger: 'blur' }
				],
				password: [
					{ required: true, message: 'New password is required', trigger: 'blur' },
					{ min: 8, message: 'Password must be at least 8 characters long', trigger: 'blur' },
					{ pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/, message: 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character', trigger: 'blur' }
				],
				confirm: [
					{ required: true, message: 'Confirm password is required', trigger: 'blur' },
					{ validator: this.validateConfirm, trigger: 'blur' }
				]
			}
		}
	},
	computed: {
		formInvalid() {
			return this.form.old === '' || this.form.password === '' || this.form.confirm === '';
		}
	},
	methods: {
		validateConfirm(rule, value, callback) {
			if (value !== this.form.password) {
				callback(new Error('The two passwords do not match'));
			} else {
				callback();
			}
		},
		submit() {
			this.$confirm('Are you sure you want to change your password?', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.$apix.sendHttpRequest('PUT', 'me/reset-password', this.form)
							.then(res => {
								this.$notify.success({title: 'Password Change', message: 'Password successfully changed'});
								this.closeModal();
							})
							// .catch(err => {
							// 	this.$notify.error({title: 'Password Change', message: 'Password change failed'});
							// });
					}
				});
			}).catch(() => {
				this.$notify.info({title: 'Password Change', message: 'Password change cancelled'});
			});
		},
		onFinish() {
			this.$emit('onFinish', 'showPasswordResetModal')
		},
		closeModal() {
			this.form = {
				old: '',
				password: '',
				confirm: ''
			};
			this.$emit('closeModal');
		}
	},

}
</script>