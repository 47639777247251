export const worksheetErrors = {
	id: { s: false, m: '' },
	external_id: { s: false, m: '' },
	in_use: { s: false, m: '' },
	keys: { s: false, m: '' },
	language: { s: false, m: '' },
	parse_date_format: { s: false, m: '' },
}

export const spreadSheetErrors = {
	hash: { s: false, m: '' },
	title: { s: false, m: '' },
	integration_id: { s: false, m: '' },
	brand_id: { s: false, m: '' },
	jc_failed: { s: false, m: '' },
	jc_success: { s: false, m: '' },
	worksheet: []
}