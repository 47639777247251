import validator from "validator";

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

export function isValidRegex(regex) {
    try {
        new RegExp(regex);
        return true;
    } catch(e) {
        console.error('[VALIDATE](isValidRegex) Error: ', e);
        return false;
    }
}

export const validateAlphaNumeric = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(`${value}`)) callback(new Error('Must be alphanumeric'));
    else callback();
}



export const validateAlphaNumericAndExists = async (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') return callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(`${value}`)) return callback(new Error('Must be alphanumeric'));
    const {topic, app, action = 'create', original} = rule;
    const result = await app.$apix.sendHttpRequest('GET', `${topic}/exists/${value}`, { action, original });
    if( result.exists) callback(new Error('The entry already exists'));
    else return callback();
}

export const validateNumericAndExists = async (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') return callback(new Error(`Field cannot be empty`));
    if(!validator.isNumeric(value)) callback(new Error('Must be numeric'));
    const {topic, app, action = 'create', original} = rule;
    const result = await app.$apix.sendHttpRequest('GET', `${topic}/exists/${value}`, { action, original });
    if( result.exists) callback(new Error('The entry already exists'));
    else return callback();
}


export const validateIPAndExists = async (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') return callback(new Error(`Field cannot be empty`));
    if(!validator.isIP(`${value}`)) return callback(new Error('Must be an IP'));
    const {topic, app, action = 'create', original} = rule;
    const result = await app.$apix.sendHttpRequest('GET', `${topic}/exists`, { action, original, value });
    if( result.exists) callback(new Error(`The ${topic} name already exists`));
    else return callback();
}

export const validateCountryIso = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(value)) callback(new Error('Must be alphanumeric'));
    if(value.length !== 2) callback(new Error('Exact 2 characters'))
    else callback();
}

export const validateCountryIso3 = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isAscii(value)) callback(new Error('Must be alphanumeric'));
    if(value.length !== 3) callback(new Error('Exact 3 characters'))
    else callback();
}

export const validateUUIDToken = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isUUID(value)) callback(new Error('Wrong token format'));
    else callback();
}

export const validatePass = (rule, value, callback) => {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    if(!rule.required && value === '') return callback()
    if(value === '') callback();
    else if (value !== '' && !regex.test(value)) callback(new Error('Illegal Password'));
    else callback();
};

export const validateEmail = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Field cannot be empty`));
    if(!validator.isEmail(value)) callback(new Error('Wrong email format'));
    else callback();
}

export const validateIP = (rule, value, callback) => {

console.log(value)
    if(!rule.required && value === '') return callback()
    if(value === '') callback();
    else if(value !== '' && !validator.isIP(value)) callback(new Error('Illegal IP Format'));
    else callback();
}

export const validateIPArray = (rule, value, callback) => {
    if(value.length === 0) callback();
    else {
        for (const v of value) {
            if (!validator.isIP(v)) callback(new Error('Illegal IP Format'));
        }
    }
    callback();
}

export const validateHref = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));
    if(!validator.isURL(value)) callback(new Error('Wrong URL format!'));
    else callback();
}

export const validateSelect = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error(`Must select an option`));
    else callback();
}

export const notEmpty = (rule, value, callback) => {
    if(value === '') callback(new Error("Field must be set"));
    else callback();
};


export const validateNumeric = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));
    if(!validator.isNumeric(value)) callback(new Error('Must be numeric'));
    else callback();
}

export const validateIsNumber = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));
    if(Number.isInteger(value)) callback(new Error('Must be numeric'));
    if(isNaN(value)) callback(new Error('Must be numeric'));
    else callback();

}

export const validatePort = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value <=0 || value > 65536) callback(new Error('Port must be between 0 and 65536'))
    else callback();

}

export const validateIsInteger = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));

    if(!Number.isInteger(value)) callback(new Error('Must be an integer'));
    if(isNaN(value)) callback(new Error('Must be numeric'));
    else callback();

}

export const validateJSON = (rule, value, callback) => {
    if(!rule.required && value === '') return callback()
    if(value === '') callback(new Error('Field cannot be empty'));
    try {
        let a = JSON.parse(value);
        callback();
    } catch (e) {
        callback(new Error('Invalid JSON Format'));
    }
}

/* Regular validations */

export const validateUrlOrTemplate = (input) => {
    const urlRegex = /^(https?:\/\/)?(localhost:\d{1,5}|([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(:\d{1,5})?(\/.*)?$/;

    // Updated Regex to check if the value starts with a template like ::TRAFFIC-REGULAR-EXAMPLE:: and may have a path after
    const templateRegex = /^::[^|]+::(?:\/[^|]*)?$/;

    if (urlRegex.test(input)) {
        console.log(`Validation passed for URL: "${input}"`);
        return true;
    } else if (templateRegex.test(input)) {
        console.log(`Validation passed for template: "${input}"`);
        return true;
    } else {
        console.log(`Validation failed for: "${input}"`);
        return false;
    }
}