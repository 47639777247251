<template lang="pug">
		el-form(:form="form" model="form" layout="vertical")
				el-row.inner-form()

						el-col(:xs="24" :sm="12")
								el-form-item(:label="Username" label-position="top" label-width="auto" :error="error.username.s ? error.username.m : ''")
										el-input(type="text", v-model="form.username", placeholder="username" )
						el-col(:xs="24" :sm="12")
								el-form-item(label="First Name" label-position="top" label-width="auto" :error="error.first_name.s ? error.first_name.m : ''")
										el-input(type="password" v-model="form.first_name", placeholder="First name" )
						el-col(:xs="24" :sm="12")
								el-form-item(label="Last Name" label-position="top" label-width="auto" :error="error.last_name.s ? error.last_name.m : ''")
										el-input(type="password" v-model="form.last_name", placeholder="Last name" :maxlength="6")
						el-col(:xs="24" :sm="12")
								el-form-item(label="Email" label-position="top" label-width="auto" :error="error.email.s ? error.email.m : ''")
										el-input(type="password" v-model="form.email", placeholder="Email address" :maxlength="6")

</template>
<style lang="scss"></style>
<script>
const $errors = {
		first_name: { s: false, m: '' },
		last_name: { s: false, m: '' },
		user_name: { s: false, m: '' },
		email: { s: false, m: '' },
}

export default {
		name: 'me-updates',
		data() {
				return {
						errors: JSON.parse(JSON.stringify($errors)),
						form: {
								first_name: '',
								last_name: '',
								user_name: '',
								email: '',
						}
				}
		},
		methods: {

				onFinish() {
						this.$emit('modal', 'showUpdatesModal')
				}
		}
}
</script>