<template lang="pug">
  el-form(:form="form" :model="form" layout="vertical" :rules="rules" ref="form")
    el-row.inner-form(:gutter="24")
      el-col(:xs="24" :sm="16")
        .text-left
          h5 Note:
          p Your current Telegram ID is {{ telegram_id }}. You can change it to another one or return to the previous one if you need. Remember to subscribe to the bot again if you change your Telegram ID.
      el-col(:xs="24" :sm="8")
        el-form-item(label="Telegram ID" label-position="top" label-width="auto" prop="telegram_bot_id" )
          el-input(type="text" v-model="form.telegram_bot_id", placeholder="Telegram ID" )
    .d-flex.justify-content-center.mt-3
      el-button(type='success', @click='changeTelegramId') Submit
      el-button(type='danger', @click='closeModal') Close

</template>

<style lang="scss">

</style>

<script>
import {validateAlphaNumeric} from "../../utils/validate";

export default {
  name: 'telegram-change-id',
  props: {
    telegram_id: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.form.telegram_bot_id = this.telegram_id;
  },
  data() {
    return {
      form: {
        telegram_bot_id: ''
      },
      rules: {
        telegram_bot_id: { required: true, validator: validateAlphaNumeric, trigger: 'blur' },
      }
    }
  },
  methods: {
    changeTelegramId() {
      this.$refs.form.validate(async valid => {
        if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
        this.$apix.sendHttpRequest('PUT', 'me/telegram-id', {telegram_bot_id: this.form.telegram_bot_id})
          .then(res => {
            // console.log(res);
            this.$message.success({title: 'Success', message: 'Your Telegram ID has been changed successfully'});
            this.$emit('onFinish', 'showTelegramChangeModal');
            // this.$emit('closeModal');
          });
      });
      // this.$emit('modal', 'showUpdatesModal')
    },
    closeModal() {
      this.$emit('closeModal');
    }
  }
}
</script>