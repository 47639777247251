export const worksheet = {
	external_id: '',
	custom_fix_id: null,
	in_use: true,
	keys: ["DATE","EMAIL","NAME","COUNTRY","PHONE"],
	columns_parse_qs_list: [],
	language: 'en',
	excepts_mails: 'NORMAL',
	country_by_phone_guess: false,
	parse_date_format: '',
	// Rate Limit
	rate_limit_toggle: false,
	registration_attempt_threshold: 1,
	registration_attempt_ttl: 300,
	auto_unlock_interval: 1,
	locked_at: null
}